define("discourse/plugins/discourse-follow/discourse/components/follow-button", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _decorators, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("user", "currentUser"), _dec2 = (0, _decorators.default)("isFollowed", "canFollow"), _dec3 = (0, _decorators.default)("isFollowed", "canFollow"), (_obj = {
    loading: false,
    isFollowed: (0, _computed.alias)("user.is_followed"),
    canFollow: (0, _computed.alias)("user.can_follow"),
    showButton: function showButton(user, currentUser) {
      if (!currentUser) {
        return false;
      }

      if (currentUser.id === user.id) {
        return false;
      }

      if (user.suspended) {
        return false;
      }

      if (user.staged) {
        return false;
      }

      if (user.id < 1) {
        // bot
        return false;
      }

      return true;
    },
    labelKey: function labelKey(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "follow.unfollow_button_label";
      } else {
        return "follow.follow_button_label";
      }
    },
    icon: function icon(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "user-times";
      } else {
        return "user-plus";
      }
    },
    toggleFollow: function toggleFollow() {
      var _this = this;

      var type = this.isFollowed ? "DELETE" : "PUT";
      this.set("loading", true);
      (0, _ajax.ajax)("/follow/".concat(this.user.username, ".json"), {
        type: type
      }).then(function () {
        _this.set("isFollowed", !_this.isFollowed);
      }).catch(_ajaxError.popupAjaxError).finally(function () {
        _this.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "showButton", [_dec], Object.getOwnPropertyDescriptor(_obj, "showButton"), _obj), _applyDecoratedDescriptor(_obj, "labelKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "labelKey"), _obj), _applyDecoratedDescriptor(_obj, "icon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "icon"), _obj), _applyDecoratedDescriptor(_obj, "toggleFollow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleFollow"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-feed-post", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ["user-stream-item", "item"]
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var preferences = ["notify_me_when_followed", "notify_followed_user_when_followed", "notify_me_when_followed_replies", "notify_me_when_followed_creates_topic", "allow_people_to_follow_me"];

  var _default = _component.default.extend((_dec = _decorators.observes.apply(void 0, _toConsumableArray(preferences.map(function (p) {
    return "user.".concat(p);
  }))), (_obj = {
    _updatePreferences: function _updatePreferences() {
      var _this = this;

      if (!this.user.custom_fields) {
        this.user.set("custom_fields", {});
      }

      preferences.forEach(function (p) {
        _this.user.set("custom_fields.".concat(p), _this.user[p]);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_updatePreferences", [_dec], Object.getOwnPropertyDescriptor(_obj, "_updatePreferences"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-post-stream", ["exports", "discourse/components/user-stream"], function (_exports, _userStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _userStream.default;
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-statistic", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("context"), (_obj = {
    classNames: "follow-statistic",
    init: function init() {
      this._super();

      this.set("tagName", this.get("isCard") ? "h3" : "div");
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.scheduleOnce("afterRender", function () {
        var parent = _this.get("isCard") ? ".card-content .metadata" : ".user-main .secondary dl";

        _this.$().prependTo(parent);
      });
    },
    isCard: function isCard(context) {
      return context === "card";
    }
  }, (_applyDecoratedDescriptor(_obj, "isCard", [_dec], Object.getOwnPropertyDescriptor(_obj, "isCard"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-users-list", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/computed", "@ember/object/computed"], function (_exports, _component, _decorators, _computed, _computed2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("type", "viewingSelf"), (_obj = {
    hasUsers: (0, _computed2.notEmpty)("users"),
    viewingSelf: (0, _computed.propertyEqual)("user.username", "currentUser.username"),
    noneMessage: function noneMessage(type, viewingSelf) {
      var key = viewingSelf ? "none" : "none_other";
      return "user.".concat(type, ".").concat(key);
    }
  }, (_applyDecoratedDescriptor(_obj, "noneMessage", [_dec], Object.getOwnPropertyDescriptor(_obj, "noneMessage"), _obj)), _obj)));

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-card-additional-buttons/follow-button-container"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"follow-button\",null,[[\"user\"],[[24,[\"user\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-card-additional-buttons/follow-button-container"}});
Ember.TEMPLATES["javascripts/discourse/connectors/user-card-metadata/follow-statistics-user-card"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"user\",\"total_following\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"user.following.label\"],null],false],[7,\"span\",true],[10,\"class\",\"value\"],[8],[1,[24,[\"user\",\"total_following\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"user\",\"total_followers\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"user.followers.label\"],null],false],[7,\"span\",true],[10,\"class\",\"value\"],[8],[1,[24,[\"user\",\"total_followers\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-card-metadata/follow-statistics-user-card"}});
define("discourse/plugins/discourse-follow/discourse/connectors/user-card-metadata/follow-statistics-user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(_, component) {
      return component.siteSettings.follow_show_statistics_on_profile;
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-main-nav/follow-user-container"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"can_see_network_tab\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\"],[\"follow\"]],{\"statements\":[[1,[28,\"d-icon\",[\"users\"],null],false],[1,[28,\"i18n\",[\"user.follow_nav\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-main-nav/follow-user-container"}});
Ember.TEMPLATES["javascripts/discourse/connectors/user-preferences-notifications/follow-notification-preferences-container"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"follow-notification-preferences\",null,[[\"user\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-preferences-notifications/follow-notification-preferences-container"}});
define("discourse/plugins/discourse-follow/discourse/connectors/user-preferences-notifications/follow-notification-preferences-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(_, component) {
      return component.siteSettings.follow_notifications_enabled;
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-profile-controls/follow-button-container"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"follow-button\",null,[[\"user\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-profile-controls/follow-button-container"}});
Ember.TEMPLATES["javascripts/discourse/connectors/user-profile-secondary/follow-statistics-user"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"total_following\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"follow-statistic\",null,[[\"label\",\"total\",\"context\"],[\"user.following.label\",[24,[\"model\",\"total_following\"]],\"profile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"total_followers\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"follow-statistic\",null,[[\"label\",\"total\",\"context\"],[\"user.followers.label\",[24,[\"model\",\"total_followers\"]],\"profile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-profile-secondary/follow-statistics-user"}});
define("discourse/plugins/discourse-follow/discourse/connectors/user-profile-secondary/follow-statistics-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(_, component) {
      return component.siteSettings.follow_show_statistics_on_profile;
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/controllers/feed", ["exports", "@ember/controller", "discourse/lib/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    viewingSelf: (0, _computed.propertyEqual)("model.user.id", "currentUser.id")
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/controllers/follow", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({});

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/initializers/follow-initializer", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "follow-plugin-initializer",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.8.10", function (api) {
        var currentUser = api.getCurrentUser();

        if (!currentUser) {
          return;
        }

        api.replaceIcon("notification.following", "discourse-follow-new-follower");
        api.replaceIcon("notification.following_created_topic", "discourse-follow-new-topic");
        api.replaceIcon("notification.following_replied", "discourse-follow-new-reply"); // workaround to make core save custom fields when changing
        // preferences

        api.modifyClass("controller:preferences/notifications", {
          pluginId: "discourse-follow-notification-preference",
          actions: {
            save: function save() {
              if (!this.saveAttrNames.includes("custom_fields")) {
                this.saveAttrNames.push("custom_fields");
              }

              this._super.apply(this, arguments);
            }
          }
        });
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/models/post-stream", ["exports", "discourse-common/utils/decorators", "discourse/models/rest", "@ember/object/computed", "rsvp", "discourse/models/topic", "discourse/models/user", "discourse/lib/ajax", "@ember/object"], function (_exports, _decorators, _rest, _computed, _rsvp, _topic, _user, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // this class implements an interface similar to the `UserStream` class in core
  // (app/models/user-stream.js) so we can use it with the `{{user-stream}}`
  // component (in core as well) which expects a `UserStream` instance.
  var _default = _rest.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("loading", "content.length"), (_obj = {
    loading: false,
    itemsLoaded: 0,
    canLoadMore: true,
    lastPostCreatedAt: (0, _computed.reads)("content.lastObject.created_at"),
    _initialize: function _initialize() {
      this.set("content", []);
    },
    noContent: function noContent(loading, length) {
      return !loading && length === 0;
    },
    findItems: function findItems() {
      var _this = this;

      if (!this.canLoadMore || this.loading) {
        return _rsvp.Promise.resolve();
      }

      this.set("loading", true);
      var data = {};

      if (this.lastPostCreatedAt) {
        data.created_before = this.lastPostCreatedAt;
      }

      return (0, _ajax.ajax)("/follow/posts/".concat(this.user.username), {
        data: data
      }).then(function (content) {
        var posts = content.posts.map(function (post) {
          post.user = _user.default.create(post.user);
          post.topic.category_id = post.category_id;
          delete post.category_id;
          post.topic = _topic.default.create(post.topic);
          return _object.default.create(post);
        });
        return {
          posts: posts,
          hasMore: content.extras.has_more
        };
      }).then(function (_ref) {
        var posts = _ref.posts,
            hasMore = _ref.hasMore;

        _this.content.addObjects(posts);

        _this.set("itemsLoaded", _this.content.length);

        _this.set("canLoadMore", hasMore);
      }).finally(function () {
        _this.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_initialize", [_dec], Object.getOwnPropertyDescriptor(_obj, "_initialize"), _obj), _applyDecoratedDescriptor(_obj, "noContent", [_dec2], Object.getOwnPropertyDescriptor(_obj, "noContent"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/feed", ["exports", "discourse/routes/discourse", "../models/post-stream"], function (_exports, _discourse, _postStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model() {
      return _postStream.default.create({
        user: this.modelFor("user")
      });
    },
    afterModel: function afterModel(model) {
      return model.findItems();
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/follow-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel() {
      var _this$currentUser;

      var model = this.modelFor("user");
      var canSeeFollowers = model.can_see_followers;
      var canSeeFollowing = model.can_see_following;

      if (((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id) === model.id) {
        this.replaceWith("feed");
      } else if (canSeeFollowing) {
        this.replaceWith("following");
      } else if (canSeeFollowers) {
        this.replaceWith("followers");
      } else {
        this.replaceWith("user");
      }
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/followers", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model() {
      return (0, _ajax.ajax)("/u/".concat(this.paramsFor("user").username, "/follow/followers"));
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        users: model,
        user: this.modelFor("user")
      });
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/following", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model() {
      return (0, _ajax.ajax)("/u/".concat(this.paramsFor("user").username, "/follow/following"));
    },
    setupController: function setupController(controller, model) {
      var user = this.modelFor("user");
      controller.setProperties({
        users: model,
        user: user
      });
    }
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/follow-button"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"showButton\"]],[24,[\"canFollow\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"label\",\"icon\",\"disabled\",\"action\"],[[24,[\"labelKey\"]],[24,[\"icon\"]],[24,[\"loading\"]],[28,\"action\",[[23,0,[]],\"toggleFollow\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/follow-button"}});
Ember.TEMPLATES["javascripts/components/follow-feed-post"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"clearfix info\"],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"href\",[24,[\"post\",\"user\",\"userUrl\"]]],[11,\"data-user-card\",[24,[\"post\",\"user\",\"username\"]]],[10,\"class\",\"avatar-link\"],[8],[0,\"\\n    \"],[1,[28,\"avatar\",[[24,[\"post\",\"user\"]]],[[\"imageSize\",\"extraClasses\",\"ignoreTitle\"],[\"large\",\"actor\",true]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"time\"],[8],[1,[28,\"format-date\",[[24,[\"post\",\"created_at\"]]],[[\"leaveAgo\"],[\"true\"]]],false],[9],[0,\"\\n  \"],[1,[28,\"expand-post\",null,[[\"item\"],[[24,[\"post\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"stream-topic-details\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"stream-topic-title\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[28,\"get-url\",[[24,[\"post\",\"url\"]]],null]],[8],[1,[28,\"html-safe\",[[24,[\"post\",\"topic\",\"fancyTitle\"]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"group-post-category\"],[8],[1,[28,\"category-link\",[[24,[\"post\",\"topic\",\"category\"]]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"excerpt\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"post\",\"expandedExcerpt\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"html-safe\",[[24,[\"post\",\"expandedExcerpt\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"html-safe\",[[24,[\"post\",\"excerpt\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/follow-feed-post"}});
Ember.TEMPLATES["javascripts/components/follow-notification-preferences"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"control-group follow-notifications\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"i18n\",[\"user.follow.label\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n    \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.allow_people_to_follow_me\",[24,[\"user\",\"allow_people_to_follow_me\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed\",[24,[\"user\",\"notify_me_when_followed\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_followed_user_when_followed\",[24,[\"user\",\"notify_followed_user_when_followed\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed_replies\",[24,[\"user\",\"notify_me_when_followed_replies\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed_creates_topic\",[24,[\"user\",\"notify_me_when_followed_creates_topic\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/follow-notification-preferences"}});
Ember.TEMPLATES["javascripts/components/follow-post-stream"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[\"post\"],\"statements\":[[4,\"each\",[[24,[\"stream\",\"content\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"follow-feed-post\",null,[[\"post\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/follow-post-stream"}});
Ember.TEMPLATES["javascripts/components/follow-statistic"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isCard\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"desc\"],[8],[1,[28,\"i18n\",[[24,[\"label\"]]],null],false],[9],[7,\"span\",true],[8],[1,[22,\"total\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"dt\",true],[8],[1,[28,\"i18n\",[[24,[\"label\"]]],null],false],[9],[7,\"dd\",true],[8],[1,[22,\"total\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/follow-statistic"}});
Ember.TEMPLATES["javascripts/components/follow-users-list"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[\"user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"follow-users\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasUsers\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"users\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"user-info\",null,[[\"user\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[28,\"i18n\",[[24,[\"noneMessage\"]]],[[\"username\"],[[24,[\"user\",\"username\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/follow-users-list"}});
Ember.TEMPLATES["javascripts/feed"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"noContent\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"viewingSelf\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[28,\"i18n\",[\"user.feed.empty_feed_you\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[28,\"i18n\",[\"user.feed.empty_feed_other\"],[[\"username\"],[[24,[\"user\",\"username\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"follow-post-stream\",null,[[\"stream\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/feed"}});
Ember.TEMPLATES["javascripts/follow"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"d-section\",null,[[\"pageClass\",\"class\",\"scrollTop\"],[\"user-follow\",\"user-secondary-navigation\",false]],{\"statements\":[[4,\"mobile-nav\",null,[[\"class\",\"desktopClass\"],[\"activity-nav\",\"action-list follow-list nav-stacked\"]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"id\"]],[24,[\"currentUser\",\"id\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"feed\"]],{\"statements\":[[1,[28,\"i18n\",[\"user.feed.label\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"can_see_following\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"following\"]],{\"statements\":[[1,[28,\"i18n\",[\"user.following.label\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"model\",\"can_see_followers\"]],[24,[\"model\",\"allow_people_to_follow_me\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"followers\"]],{\"statements\":[[1,[28,\"i18n\",[\"user.followers.label\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"user-content user-follows-tab\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/follow"}});
Ember.TEMPLATES["javascripts/followers"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"follow-users-list\",null,[[\"users\",\"type\",\"user\"],[[24,[\"users\"]],\"followers\",[24,[\"user\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/followers"}});
Ember.TEMPLATES["javascripts/following"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"follow-users-list\",null,[[\"users\",\"type\",\"user\"],[[24,[\"users\"]],\"following\",[24,[\"user\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/following"}});
define("discourse/plugins/discourse-follow/discourse/users-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    resource: "user",
    map: function map() {
      this.route("follow", {
        path: "/follow",
        resetNamespace: true
      }, function () {
        this.route("feed", {
          path: "/feed",
          resetNamespace: true
        });
        this.route("followers", {
          path: "/followers",
          resetNamespace: true
        });
        this.route("following", {
          path: "/following",
          resetNamespace: true
        });
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/widgets/following-notification-item", ["discourse/widgets/widget", "discourse/widgets/default-notification-item", "discourse/lib/url", "I18n"], function (_widget, _defaultNotificationItem, _url, _I18n) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "following-notification-item", {
    description: function description() {
      return _I18n.default.t("notifications.following_description");
    },
    url: function url(data) {
      return (0, _url.userPath)(data.display_username);
    }
  });
});

